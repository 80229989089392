<template>
  <create-discrepancy-report
    :cash-account-id="afcId"
    :is-submitting="isSubmitting"
    :submission-error="error"
    :discrepancy-options="natureOfDiscrepancyTypes"
    @submit="submit"
    @store-offline="storeOffline"
    @back="$router.push({ name: 'AFC Main Page' })"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { createDiscrepancyReport } from "@/services/cashManagement.service";
import CreateDiscrepancyReport from "../../_CreateDiscrepancyReport.vue";
import { discrepancyNature } from "@/constants/APIEnums/cashManagementEnums";

export default {
  components: {
    CreateDiscrepancyReport,
  },
  data() {
    return {
      isSubmitting: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
    }),
    natureOfDiscrepancyTypes() {
      return [
        {
          value: discrepancyNature.CASH_BAG.VALUE,
          label: "Cashbag e-log",
        },
        {
          value: discrepancyNature.CASH_BOX.VALUE,
          label: "Cashbox e-log",
        },
        {
          value: discrepancyNature.COIN_FLOAT.VALUE,
          label: "Coinfloat e-log",
        },
        {
          value: discrepancyNature.SCCF.VALUE,
          label: "SCCF",
        },
        {
          value: discrepancyNature.STATION_TRANSFER.VALUE,
          label: "Station Transfer",
        },
        {
          value: discrepancyNature.CASH_DECLARATION_AFC.VALUE,
          label: "Cash Declaration",
        },
        {
          value: discrepancyNature.OTHERS.VALUE,
          label: "Others",
        },
      ];
    },
  },
  methods: {
    async submit(emitData) {
      this.isSubmitting = true;
      this.submissionData = emitData;
      const result = await createDiscrepancyReport(this.submissionData);
      this.isSubmitting = false;
      this.showModal = false;
      if (result.success) {
        this.$router.push({ name: "AFC Main Page" });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    storeOffline(submissionData) {
      this.$store.dispatch(
        "offlineData/createAFCDiscrepancy",
        submissionData
      );
      this.$router.push({ name: "AFC Main Page" });
    }
  },
};
</script>
